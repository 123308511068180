<template>
  <div class="m-0 px-10 py-10">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "App",
    components: {},
  };
</script>

<style></style>
